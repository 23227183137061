export default {
    debug: false,

    testing: false,

    maintenance: false,

    backendBaseUrl: 'https://ascensores-back.wemake.pt',

    translations: {
        default:  'pt',
        fallback: 'en',
        remoteEndpoint: 'https://ascensores-back.wemake.pt/api/translations/from-locale/{{lng}}',
        localEndpoint: './locales/{{lng}}/translations.json',
    },

    webSockets: {
        supported:   true,
        broadcaster: {
            name:      'socket.io',
            endpoint:  'https://ascensores-back.wemake.pt:6041',
            apiKey:    'nrhmk8ic64lgnqbs6t794gpj6per33ho5q34j2mrgia61j8hf238rf0c014b',
            namespace: 'Gaiurb.LiftingEquipmentManagement.Events',
        },
    },

    googleMapsApi: {
        url: null,
        key: null,
    },

    productName: 'Plataforma Ascensores',

    clientName: 'Roboyo',
};

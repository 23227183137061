import { inject }        from 'aurelia-framework';
import { AppContainer }  from 'resources/services/app-container';
import { BaseViewModel } from 'base-view-model';

@inject(AppContainer)
export class BaseListViewModel extends BaseViewModel {

    filterFormIsDirty = false;

    /**
     * Constructor
     *
     * @param appContainer
     */
    constructor(appContainer) {
        super(appContainer);
    }

    /**
     * Exports the listing to Excel
     * Assumptions: a `filterModel`, `downloader` instance and repository `export` method must exist
     *
     * @param filename
     */
    exportListing(filename = 'listing') {
        const filterModel = Object.assign({
            sorting: this.datatable.instance.getSortingColumn(),
        }, this.filterModel);

        this.repository.export(filterModel).then(response => this.downloader.download(response, filename, 'xlsx'));
    }

}
